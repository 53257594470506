<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain">
      <img src="@/assets/p_02.jpg" />
      <div class="p20">
        <div class="boxText">
          <p>国家一级指挥、享受国务院政府津贴。第十三、十四届全国政协委员、中国音乐家协会理事、中国民族管弦乐学会常务理事。中国广播民族乐团、中国电影交响乐团、中国歌剧舞剧院、澳门中乐团（2003—2016）、中国东方交响乐团、东方中乐团艺术总监兼首席指挥；乌克兰国家交响乐团、奥地利萨尔茨堡莫扎特交响乐团、奥地利格拉兹交响乐团、韩国釜山国立国乐管弦乐团、捷克国家交响乐团常任客席指挥。2017年6月担任苏州民族管弦乐团艺术总监兼首席指挥。</p>
          <p>彭家鹏毕业于中央音乐学院指挥系，1992年获得硕士学位。曾在荷兰获得第35届国际康德拉申指挥大师班奖，是该届获奖者中唯一的亚洲青年指挥家。参加国际指挥大师班（乌克兰基辅）学习，以第一名的成绩完成学业。曾获“中国金唱片指挥特别奖”、国家“四个一批”人才、 “中国十大杰出青年”称号。</p>
          <img src="@/assets/p_05.jpg"/>
          <p>自2000年在奥地利维也纳金色大厅成功指挥“中国—维也纳新春交响音乐会”后，他连续13年在维也纳金色大厅指挥世界相关乐团，引起巨大轰动。他先后率团赴瑞士、德国、美国举行中国民族交响音乐会，应邀指挥日本、朝鲜、韩国、新加坡、香港、澳门、捷克、乌克兰及奥地利的著名乐团。奥地利《信报》称他为“兼有日本小泽征尔和意大利指挥大师穆蒂的指挥风范”，当代奥地利最具有权威的音乐评论家辛科·卫奇评价他“生机盎然、热情洋溢、手部动作快而线条优美，具有独特的风格”。</p>

          <p>2013年彭家鹏远赴以培养世界大师级指挥而闻名的维也纳音乐表演艺术大学深造欧洲歌剧，获维也纳音乐表演艺术大学歌剧指挥毕业证书，得到导师康阿德•莱茵特纳教授评语：“彭家鹏先生有着非常不凡的才华、高度的敏锐和细腻的乐感，这使他的指挥艺术臻于完美”。</p>

          <p>他积极付诸于中国民族歌剧和民族交响音乐的探索与实践，曾在第一届中国歌剧节担任歌剧《原野》指挥，第二届歌剧节担任意大利歌剧“茶花女”指挥并获奖。</p>

        </div>
        
      </div>
        
      
      <Footer />

    </div>
    
  </div>
</template>

<style scoped>
 
</style>
<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'
export default {
  
  name: 'App',
  components: {
    Nav,
    Footer
  },
  data () {
    return {
      isIndex: false,
      joinList:[
        {
          title:"招聘岗位名称1",
          othe:"时间/地点",
          describe:"苏州民族管弦乐团是由苏州市和高新区共同成立的职业乐团，为市级公益性文艺团体。乐团由曾经十三次赴维也纳金色大厅指挥的著名指挥家彭家鹏任艺术总监兼首席指挥，由著名二胡演奏家、作曲家朱昌耀任艺术指导。聘请中国音乐家协会名誉主席、著名作曲家赵季平，中国民族管弦乐学会会长、著名作曲家刘锡津，中国民族管弦乐学会副会长兼秘书长王书伟，中国民族管弦乐学会荣誉会长、著名作曲家顾冠仁任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:true
        },{
          title:"招聘岗位名称2",
          othe:"时间/地点",
          describe:"苏州民族管弦乐团是由苏州市和高新区、著名作曲家顾冠仁任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:false
        },{
          title:"招聘岗位名称3",
          othe:"时间/地点",
          describe:"乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:false
        }
      ]
    }
  },
  computed: {
      
  },
  mounted() {
    
  },
  methods:{
    joinView(index){
      this.joinList[index].show = !this.joinList[index].show
    }
    // getCompanys(){
      
    // }
    
  },
}
</script>

